<template>
  <div class="default-search">
    <div class="none"></div>
    <div class="search-box">
      <div class="search-title">
        <h4>搜索结果</h4>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>搜索内容</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="search-list">
        <div class="list-item" v-if="searchList" v-for="(search, index) in searchList">
          <div class="item-title" @click="toListDetail(search)">{{search.title}}</div>
          <div class="item-content mui-ellipsis-3 ie-mui-ellipsis-3">
            {{search.content}}
          </div>
        </div>
        <xgj-empty v-if="searchList.length === 0" :title="`暂无${searchWord}搜索相关数据`" height="300px"></xgj-empty>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import _ from 'lodash'
  export default {
    name: 'search',
    data: () => ({
      searchList: []
    }),
    computed: {
      ...mapState({
        searchWord: state => state.user.searchWord,
        navList: state => state.Sea.navList
      })
    },
    watch: {
      searchWord(val) {
        this.searchContent(val)
      },
      deep: true
    },
    created() {
      this.searchContent(this.searchWord)
    },
    methods: {
      toListDetail(item) {
        let currentNav
            ,routerName;
        currentNav = this.navList[_.findIndex(this.navList, o => { return o.id == item.website_column.parent_id})];
        // currentNav.children.reverse();
        if (currentNav) {
          switch (currentNav.name) {
            case '新闻资讯' :
              routerName = 'news-list';
              break;
            case '政策法规' :
              routerName = 'policy-list';
              break;
            default :
              routerName = false
          }
          if (routerName) {
            this.$router.push({
              name: routerName,
              params: {
                id: item.id,
                type: _.findIndex(currentNav.children,  o => { return o.id == item.website_column.id})
              }
            });
          } else {
            this.$message.error('暂不支持查看该文章详情')
          }
        } else {
          this.$message.error('非导航栏目文章,不可查看')
        }
      },
      searchContent(val) {
        const that = this;
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          background: 'rgba(255, 255, 255, .8)'
        });
        let params = {
          partnerId: that.$store.state.user.partnerId,
          title: val,
          page: 1,
          rows: 100
        };
        that.$api.website.searchContent(params)
          .then( dates => {
            that.searchList = dates.data;
            loading.close()
          }).catch( err => {
            loading.close()
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.default-search {
  position: relative;
  width: 100%;
  background: url("../assets/images/sea-burial/new-sea-index-bg.png")no-repeat top #ffffff;
  .none {
    height: 340px;
  }
  .search-box {
    @include screen-size;
    margin: -140px auto;
    position: relative;
    min-height: 380px;
    margin-bottom: 40px;
    background: #fff;
    opacity: 0.8;
    .search-title {
      @include w-flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      h4 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #3281EC;
      }
    }
    .search-list {
      padding: 20px;
      .list-item {
        border-bottom: 2px solid #E5E5E5;
        margin-bottom: 19px;
        padding-bottom: 28px;
        .item-title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          cursor: pointer;
          color: #333333;
        }:hover{color: #3281ec}
        .item-content {
          font-size: 12px;
          font-family: Microsoft YaHei;
          color: #666666;
          margin-top: 17px;
          text-indent: 20px;
        }
        &:last-child{border: none}
      }
    }
  }
}
</style>
